<template>
  <base-authentication
    :title="`Guide de fonctionnement de l'outil - ${stepCarousel+1}/${itemsCarouselIntro.length}`"
  >
    <v-carousel 
      v-model="stepCarousel"
      :show-arrows="false"
      hide-delimiter-background
      class="carousel-intro"
      height="400"
    >
      <v-carousel-item
        v-for="(item,i) in itemsCarouselIntro"
        :key="i"
      >
        <v-sheet
          tile
          class="d-flex flex-column"
        >
          <v-card 
            flat
            width="645"
            height="247"
            color="cassiopee-purple lighten-1"
            class="pa-8"
          >
            <v-row>
              <v-col>
                <span class="carousel-title">{{ item.title }}</span>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col cols="8">
                <p class="carousel-content text-justify">
                  {{ item.content }}
                </p>
              </v-col>
              <v-col 
                cols="4"
                class="d-flex justify-center"
              >
                <v-img 
                  :src="item.image.src"
                  alt="image d'illustration"
                  :height="item.image.height"
                  :width="item.image.width"
                  contain
                />
              </v-col>
            </v-row>
          </v-card>
          <base-button 
            right
            x-small
            icon="$arrowRight"
            class="mt-4 mb-8"
            @click="guideStep(item.id)"
          >
            Poursuivre
          </base-button>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
  </base-authentication>
</template>

<script>
import BaseAuthentication from '@/components/BaseAuthentication.vue'
import BaseButton from '@/components/BaseButton.vue'

export default {
  name: "Introduction",
  components: {
    BaseAuthentication,
    BaseButton
  },
  data() {
    return {
      stepCarousel: 0,
      itemsCarouselIntro: [
        {
          id: 1,
          title: "Outil d'aide à la décision",
          content: "DataKairos est un outil d'aide à la décision permettant d'évaluer les coûts / bénéfices d'une migration des infrastructures SI hébergées en propre vers le Datacenter ESR (Enseignement Supérieur de Recherche), faisant partie, pour l'Ile-de-France, des Datacenters régionaux labellisés par le MESRI (Ministère de l'Enseignement Supérieur, de la Recherche et de l'Innovation).",
          image: {
            src: './picto-intro/step-1.png',
            height: '82', 
            width: '74'
          }
        },
        {
          id: 2,
          title: "L'objectif de l'outil",
          content: "L'objectif visé est une réduction des risques et des coûts d'hébergement ainsi qu'une augmentation de la qualité de service en s'appuyant sur un réseau de Datacenters sécurisés labellisés.",
          image: {
            src: './picto-intro/step-2.png',
            height: '90', 
            width: '98.7'
          }
        },
        {
          id: 3,
          title: "L'objectif de l'outil",
          content: "A partir des informations renseignées ainsi que d'abaques et ratios, DataKairos estime automatiquement les capacités et le coût de votre hébergement actuel, évalue son impact environnemental ainsi que le niveau de risque. DataKairos compare ensuite ces éléments de votre hébergement actuel avec un hébergement dans le Datacenter ESR labellisé par le MESRI. Les résultats de DataKairos sont factuels, basés sur des données objectives détaillées dans les documents et leur précision dépend de la qualité des réponses apportées.",
          image: {
            src: './picto-intro/step-3.png',
            height: '65', 
            width: '64'
          }
        },
        {
          id: 4,
          title: "Le périmètre",
          content: "L'Outil DataKairos traite spécifiquement d'une cible en hébergement sec. Les choix liés à la migration des infrastructures SI et/ou leur éventuelle transformation reste à évaluer par les établissements. L'utilisateur est invité à se rapprocher de l'UNIF (Université Numérique Ile-de-France) pour les établissements ESR, ou du GIPC (Groupement d'Infogérance Publique Communautaire) pour les collectivités, afin de connaitre l'étendue des offres de services et leur tarif.",
          image: {
            src: './picto-intro/step-4.png',
            height: '90', 
            width: '98.7'
          }
        },
        {
          id: 5,
          title: "Présentation du portail DataKairos",
          content: "DataKairos est accessible uniquement par Login/Mot de passe. Pour un établissement, il peut y avoir un responsable unique du remplissage de l'Outil (recommandé) qui centralise les données et les contacts, ou la création de plusieurs accès utilisateurs.",
          image: {
            src: './picto-intro/step-5.png',
            height: '100', 
            width: '80'
          }
        },
        {
          id: 6,
          title: "Présentation du portail DataKairos",
          content: "Quelle que soit l'option retenue, une réunion préalable de la gouvernance et des décideurs-ressouces (Président/Directeur, DSI, DGS, Patrimoine, etc.) est fortement recommandée afin de présenter la nature des données nécessaires en entrée, les résultats que l'on peut en attendre et d'obtenir une adhésion de tous sur la méthodologie suivie. Hub Datacenter et les équipes de l'UNIF peuvent y participer.",
          image: {
            src: './picto-intro/step-6.png',
            height: '79', 
            width: '84'
          }
        }
      ]
    }
  },
  methods: {
    guideStep(currentItem) {
      if(currentItem < this.itemsCarouselIntro.length){
        this.stepCarousel++
      }else {
        localStorage.setItem('intro', 'true');
        this.$router.push('/profile/person');
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/variables';

.carousel-intro {
  & .v-carousel__controls__item.v-btn {
    color: map-deep-get($colors, 'cassiopee-purple', 'lighten-1');
  }
  & .v-carousel__controls__item.v-btn.v-btn--active i{
    color: map-deep-get($colors, 'cassiopee-purple', 'base') !important;
    opacity: 1;
  }

  & .carousel-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 25px;
  }

  & .carousel-content {
    font-size: 12px;
    line-height: 18px;
  }
}
</style>